var InteractionTypeImpl = InteractionType.extend({

  init: function (blueConicClient, context) {
    this.blueConicClient = blueConicClient;
    this.context = context ? context : null;
    this.profile = blueConicClient.profile.getProfile();
  },

  onPrepare: function () {
    if (document.querySelector('meta[name="blaize:pageType"]')) {
      var pageType = document.querySelector('meta[name="blaize:pageType"]').getAttribute('content')
      //console.error(pageType)
      //var pageType2 = document.querySelector('meta[property="og:type"]').getAttribute('content')
      //console.error(pageType2)
      this.profile.setValue("pre_listener_page_type", pageType);
    }
  }
});